<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-grid v-if="resource">
      <ion-row>
        <ion-col>
          <ion-list>
            <ion-list-header v-if="resource.exam.exam_type.name == 'Offline'">
              Admit Card: &nbsp;
              <ion-text
                :color="
                  resource.exam_user_state.name == 'Active'
                    ? 'success'
                    : 'warning'
                "
                ><b>{{ resource.exam_user_state.name }}</b></ion-text
              >
              <ion-button
                color="success"
                fill="solid"
                style="margin-left: auto"
                @click="checkAdmitCardStatus()"
              >
                <ion-icon
                  :ios="downloadOutline"
                  :md="downloadSharp"
                  size="small"
                  slot="start"
                ></ion-icon
                >Download</ion-button
              >
            </ion-list-header>
            <ion-list-header v-else>
              Admit Card not available for Online exams
            </ion-list-header>

            <ion-item lines="none">
              <ion-label>User name: {{ user.username }}</ion-label>
            </ion-item>
            <ion-item lines="full">
              <ion-label slot="start"
                >Class: {{ user.student.section_standard.standard.name }} -
                {{ user.student.section_standard.section.name }}</ion-label
              >
              <ion-text slot="end"
                >Roll No.: {{ user.student.roll_no }}</ion-text
              >
            </ion-item>

            <ion-list-header> Exam Routine </ion-list-header>
            <ion-item lines="none" v-for="item in examSubjects" :key="item.id">
              <ion-note slot="start" color="dark">
                <b>
                  {{ moment(item.exam_schedule.start).format("L") }},
                  {{ moment(item.exam_schedule.start).format("HH:mm") }} to
                  {{ moment(item.exam_schedule.end).format("HH:mm") }}
                </b>
              </ion-note>
              <ion-label class="ion-text-end">
                {{ item.subject.name }}
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid v-else>
      <ion-row>
        <ion-col> Not found! please check again later </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import base_url from "../../../apis/base_url";

import {
  alertController,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
  IonButton,
  IonIcon,
} from "@ionic/vue";

import { downloadOutline, downloadSharp } from "ionicons/icons";

import moment from "moment";

export default {
  props: ["exam", "title"],

  components: {
    // IonIcon,
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonText,
    IonNote,
    IonButton,
    IonIcon,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: false,

      downloadOutline,
      downloadSharp,
      base_url,
      moment,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    resource() {
      return this.$store.getters["admitCard/specificResource"];
    },

    examSubjects() {
      return this.$store.getters["examSubject/allResources"];
    },

    user() {
      return this.$store.getters["auth/user"];
    },

    token() {
      return this.$store.getters["auth/token"];
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    downloadAdmitCard() {
      const url = `${this.base_url}api/v1/admit-card-print/${this.exam}?pat=${this.token}`;
      const new_download_button = document.createElement("a");
      new_download_button.href = url;
      new_download_button.download = "admit_card.pdf";
      new_download_button.click();
    },

    async offerPayment() {
      const alertDialog = await this.alerter(
        "Unpaid Due!",
        "Please clear all unpaid dues or contact principal for permission!",
        [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Pay Now",
            handler: () => {
              this.$router.push({
                name: "invoice",
              });
            },
          },
        ]
      );
      alertDialog.present();
    },

    async suggestPayment() {
      const alertDialog = await this.alerter(
        "Unpaid Due!",
        "Admit card will be marks as provisional!",
        [
          {
            text: "Download",
            cssClass: "secondary",
            handler: () => {
              this.downloadAdmitCard();
            },
          },
          {
            text: "Pay Now",
            handler: () => {
              this.$router.push({
                name: "invoice",
              });
            },
          },
        ]
      );
      alertDialog.present();
    },

    checkAdmitCardStatus() {
      const admit_card_status = this.resource.exam_user_state.name;
      if (admit_card_status == "Active" || admit_card_status == "Completed") {
        this.downloadAdmitCard();
      }
      if (admit_card_status == "Inactive") {
        this.offerPayment();
      }
      if (admit_card_status == "Provisional") {
        this.suggestPayment();
      }
    },

    async fetchResource() {
      await this.$store.dispatch("admitCard/specificResource", this.exam);
    },

    async fetchExamSubjects() {
      await this.$store.dispatch("examSubject/allResources", this.exam);
    },
  },

  async created() {
    await this.fetchResource();
    await this.fetchExamSubjects();
  },
};
</script>

<style scoped>
</style>
